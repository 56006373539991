<template>
  <div style="background:rgb(242, 242, 244);height:100%;">

    <div class="information"
      style="width: 95%;margin:10px auto;background:#fff;border-radius:5px;">
      <!-- 全部 -->

      <div class="center"
        v-for="item in list"
        :key="item.index">
        <router-link :to="'/partymember/activity/' + item.AId">
          <!-- <div class="centerTitle van-ellipsis">{{ item.Title }}</div> -->
          <!-- <div class="Content">
            <div style="display: inline-block"
              class="ContentA">
              <img :src="item.AImgUrl"
                alt="" />
            </div>
            <div style="display: inline-block"
              class="ContentB">
              <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
              <br />
              <span class="centertime">{{ item.ADate }}</span>
            </div>
          </div> -->
          <div class="Content">
            <div style="display: inline-block;width: 67%;"
              class="ContentB">
              <div class="centerTitle van-ellipsis--l2">{{ item.Title }}</div>
            </div>
            <div class="ContentA"
              ref="getHeight">
              <img v-if="item.AImgUrl"
                style="border-radius:5px;"
                :src="item.AImgUrl"
                alt="" />
            </div>
            <span class="centertime"
              style="float: left;margin-top: -10px;color:#999999;">{{ item.ADate }}</span>
          </div>
        </router-link>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetMyActivityPage } from "@/api/RealInfo";
export default {
  data () {
    return {
      akId: 0, //tab栏
      list: {}, //资讯列表
      listfrom: {}, //查询传参
      hidden: true, //没有更多数据了
    };
  },
  methods: {
    // 获取分页列表
    getList () {
      WeGetMyActivityPage({ akId: 0 })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].AImgUrl == "") {
                  this.list[i].AImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => { });
    },
    // tan点击切换事件
    onClick (e) {
      this.akId = e;
      this.getList();
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  /* margin: 10px 0px 25px 0px; */
}
</style>
<style scoped>
.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>